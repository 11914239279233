import React from "react";
import logo from '../img/logoheader.svg'

export default function Navbar(){
    return(
        <nav className="navbar navbar-inverse sub-navbar navbar-fixed-top">
  <div className="container">
    <div className="navbar-header">
      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#subenlaces">
        <span className="sr-only">Interruptor de Navegación</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      
    </div>
    <div className="collapse navbar-collapse" id="subenlaces">
      <ul className="nav navbar-nav navbar-right">
        <li><a href="#">INICIO</a></li>
        <li><a href="#">LIBROS</a></li>
        <li><a href="#">PLAN DE ESTUDIOS</a></li>
        <li className="dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">SERVICIOS EN LINEA <span className="caret"></span></a>
          <ul className="dropdown-menu" role="menu">
            <li><a href="#">Validación de certificado</a></li>
            <li><a href="#">Historial Academico</a></li>
            <li><a href="#">Inscripción</a></li>
            <li><a href="#">Certificado Parcial</a></li>
            <li><a href="#">Solicitud de Examen</a></li>
            <li className="divider"></li>
            <li><a href="#">Plataforma en linea</a></li>
          </ul>
        </li>
        <li><a href="#">CONTACTO</a></li>
      </ul>
    </div>
  </div>
</nav>
    )
}