import React from "react";
import datos from '../datos.json'

    const url = document.URL
    const urlm = document.URL.length - 37
    const urlg = url.substring(urlm) 
   




function buscarFolio(){

    const inputfolio = document.getElementById('folio').value
    if(inputfolio.length == 36){
        window.location.href= 'https://controlescolar.sidgbe.gob.mx.validacion.info/certificado/?folio='+inputfolio
        
    }
    if(inputfolio.length == 37){
        
        window.location.href= 'https://controlescolar.sidgbe.gob.mx.validacion.info/?folio='+inputfolio
    }
    else{
        document.getElementById('folio').style.color = "#9D2449"
        document.getElementById('folio').style.fontWeight = "600"
        
        
    }
    
}



export default function SIGBEDFolio(){
    console.log('ss')
   
    for( let i=0; i < datos.length; i++){
        console.log('dd')
        if(datos[i].folio == urlg)
    
        return(
        <main role="main">
        <div className="container">
            <div className="section section-error vertical-buffer">
                <div className="row">
                    <div>
                        <h2></h2>
                        <hr/> 

                    <ul className="nav nav-tabs top-buffer">
                        <li className="active"><a data-toggle="tab" href="#tab-01">Búsqueda de certificado electrónico</a></li>
                    </ul>
                        <div className="tab-content bottom-buffer">
                            <div className="tab-pane active" id="tab-01">
                                {/* <!-- Form --> */}
                                <form className="form-media form-mb disabled">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="formCurp" className="form-label">Ingresa el Folio SEP*:</label>
                                                <input type="text" className="form-control" placeholder={urlg} id="folio" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="double-form">
                                       <span>* Campos obligatorios</span>
                                       <button className="btn btn-primary btn-lg pull-right" type="button" onClick={buscarFolio}>
                                           <span className="glyphicon glyphicon-search" style={{paddingRight:"10px"}}></span>
                                           Buscar
                                       </button>
                                    </div>
                                </form>
                            </div>
                        </div>







                        <div className="center" id="resultado">
      <div className="search-result bottom-buffer">
        <div className="download-result bottom-buffer row">
            <div className="col-md-12">
                <h3>Certificado de Educación Valido</h3>
                <div className="panel panel-default">
                    <div className="panel-heading clearfix">
                        <h5>Detalle del documento</h5>
                    </div>
                    {/* <!-- Body --> */}
                    <div className="panel-body">
                        <table style={{width: "100%"}}>
                            <tbody><tr style={{borderBottom: "1px solid #ececec"}}>
                                <td style={{fontWeight: "700", width: "60%", paddingBottom: "8px"}}>Nombre(s):</td>
                                <td style={{textTransform: "uppercase"}}>{datos[i].nombre}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #ececec"}}>
                                <td style={{fontWeight: "700", width: "60%", paddingTop: "8px", paddingRight: "8px", paddingBottom: "8px"}}>
                                    CURP:
                                </td>
                                <td style={{textTransform: "uppercase"}}>{datos[i].curp}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #ececec"}}>
                                <td style={{fontWeight: "700", width: "60%", paddingTop: "8px", paddingBottom: "8px", paddingRight: "8px"}}>
                                    Fecha de Certificación:
                                </td>
                                <td style={{textTransform: "uppercase"}}>{datos[i].fechat} {datos[i].horat}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #ececec"}}>
                                <td style={{fontWeight: "700", width: "60%", paddingTop: "8px", paddingBottom: "8px", paddingRight: "8px"}}>
                                    Promedio:
                                </td>
                                <td style={{textTransform: "uppercase"}}>{datos[i].promedio}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #ececec"}}>
                                <td style={{fontWeight: "700", width: "60%", paddingTop: "8px", paddingBottom: "8px", paddingRight: "8px"}}>
                                    Folio:
                                </td>
                                <td style={{textTransform: "uppercase"}}>{datos[i].folio}</td>
                            </tr>
                        </tbody></table>
                    
                    </div>
                   <br/>
                    <div className="col-sm-12 d-flex">
                        <div className="alert alert-info text-center alerts">
                            <b>Estatus del Documento:</b>
                            <p className="mt-4 text-uppercase mt-4">REGISTRADO</p>
                        </div>
                    </div>
                    <div className="center"><p>La información presentada es de carácter informativa y para verificar la validez del documento impreso. 
                        Es una representación de la información contenida en el certificado (documento impreso)</p>
                        <p><img alt="" src="https://www.sep.gob.mx/work/models/sep1/Resource/5211/2/images/mail.png" height="30" width="30"/>&nbsp; Para corrección de datos o aclaración<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="mailto:controlescolar@sidgbe.gob.mx">controlescolar@sidgbe.gob.mx</a></p>
                            <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
     </div>
                        <hr/>
                    </div>
                </div>
            </div>
      </div>

    </main>
        )
        
    }
    return(
        <main role="main">
        <div className="container">
            <div className="section section-error vertical-buffer">
                <div className="row">
                    <div>
                        <h2></h2>
                        <hr/> 

                    <ul className="nav nav-tabs top-buffer">
                        <li className="active"><a data-toggle="tab" href="#tab-01">Búsqueda de certificado electrónico</a></li>
                    </ul>
                        <div className="tab-content bottom-buffer">
                            <div className="tab-pane active" id="tab-01">
                                {/* <!-- Form --> */}
                                <form className="form-media form-mb disabled">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="formCurp" className="form-label">Ingresa el Folio SEP*:</label>
                                                <input type="text" className="form-control"  id="folio" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="double-form">
                                       <span>* Campos obligatorios</span>
                                       <button  className="btn btn-primary btn-lg pull-right" type="button" onClick={buscarFolio}>
                                           <span className="glyphicon glyphicon-search" style={{paddingRight:"10px"}}></span>
                                           Buscar
                                       </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="center" id="resultado"><br/><div class="alert alert-danger"><h2>Error</h2><p>No se encontró información del folio</p></div></div>







                    </div>
                </div>
            </div>
       </div>

        </main>

    )
}