import { Router, BrowserRouter as Switch, Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';


import SIGBEDFolio from './components/js/SIGBEDFolio.js'
import Navbar from './components/js/Navbar.js'
import Footer from './components/js/Footer.js'
import SIGBEDFecth from './components/js/SIGBEDFetch.js';

function App() {
  return (
    <BrowserRouter>
    <Navbar/>

        <Routes>
          <Route exact path="/" element={<SIGBEDFolio/>}></Route>
          <Route exact path="/certificado" element={<SIGBEDFecth/>} />
        </Routes>
   
    </BrowserRouter>


    
  );
}

export default App;
